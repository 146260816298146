import { FC } from 'react'
import { Loader } from '@fluentui/react-northstar'
import Image from 'next/image'

const MsftLoginLight = '/ms-symbollockup_signin_light.svg'

interface IProps {
  loading: boolean
  onSignIn: () => void
}

export const SignInButton: FC<IProps> = ({ loading, onSignIn }) => {
  return (
    <>
      <div className={loading ? 'mb-2' : ''}>
        <Image
          src={MsftLoginLight}
          className="cursor-pointer"
          onClick={onSignIn}
          alt="Login with Microsoft"
          height={42}
          width={215}
        />
      </div>
      <Loader className={loading ? '' : '!hidden'} />
    </>
  )
}
